import React, {useState} from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import Slider from "rc-slider";

import "rc-slider/assets/index.css";

import {Grid} from "../../common/layout/Grid";

import {reduceString} from "../../utils/reduceString";
import {formatNumber} from "../../utils/formatNumber";
import unknownToken from "../../assets/images/icons/unknown_token.svg";
import {LoadingBox} from "../../common/loading/LoadingBox";
import {ShadowWidget} from "../../common/ShadowWidget";
import {VELockInfoKoi} from "./veLockInfoKoi";
import LaunchIcon from "@mui/icons-material/Launch";
import { Tooltip } from "../../ui/components";
import {calcTimeDelta} from "./Countdown";

import {
  Box,
  CardContent,
  Icon,
  InputCoin,
  Tabs,
  Button,
  LavaChip,
  Modal as CustomModal,
  Text,
  Empty,
  Title,
  Flex,
  Message,
  Loading,
} from "../../ui/components";

import {useWalletHook} from "../../web3/walletHook";
import {WalletState} from "../../state";
import {useWalletSelector} from "../../state/hooks";

import {useAccount} from "wagmi";
import {sendTransactionGaEvent} from "../../utils";
import {TxModal} from "../+common/TxModal";

interface ParamTypes {
  inputCurrency: string;
  outputCurrency: string;
}

enum Modal {
  Lock,
  ClaimRewards,
}

export const ConvertScreen = () => {
  const [lockTime, setLockTime] = useState(7);
  const {isConnected} = useAccount();

  const [koiAmount, setKoiAmount] = useState("0");
  const [delegate, setDelegate] = useState("");
  const [txId, setTxId] = useState("");
  const [gasSaved, setGasSaved] = useState("");
  const [confirmTime, setConfirmTime] = useState("");

  const [isDeposit, setIsDeposit] = useState(0);
  const [calculating, setCalculating] = useState(false);

  const [modal, setModal] = useState<Modal | null>(null);

  const {getKoiToken, getKoiTokenPrice, RedeemVEKOI, LockKoi, ChangeVEMuteDelegate, collectVeRewards, getDAOInfo, unlockVEKOI} =
    useWalletHook<true>();
  const daoInfo = useWalletSelector((state: WalletState) => state.daoInfo as any);
  const gasRefund = useWalletSelector((state: WalletState) => state.gasEstimateRefund);


  React.useEffect(() => {

  }, []);

  const collectRewards = async () => {
    setModal(Modal.ClaimRewards);
    setCalculating(true);
    try {
      const tx = await sendTransactionGaEvent("collect_ve_transaction", undefined, () => {
        return collectVeRewards();
      });

      if (tx.success == false) setModal(null);
      else {
        setTxId(tx.tx);
        setGasSaved(tx.gasSavedUSD!);
        setConfirmTime(tx.time);
      }
    } catch (e) {
      setModal(null);
    }
    setCalculating(false);
  };

  const redeemVeKoi = async (index) => {
    setCalculating(true);
    try {
      await sendTransactionGaEvent("unlock_ve_transaction", undefined, () => {
        return RedeemVEKOI(index);
      });
    } catch (e) {
      console.log(e);
    }
    setCalculating(false);
  };

  const lockMute = async () => {
    setModal(Modal.Lock);
    setCalculating(true);
    try {
      const tx = await sendTransactionGaEvent("lock_ve_transaction", undefined, () => {
        return unlockVEKOI();
      });
      if (tx.success == false) setModal(null);
      else {
        setTxId(tx.tx);
        setGasSaved(tx.gasSavedUSD!);
        setConfirmTime(tx.time);
      }
    } catch (e) {
      setModal(null);
    }
    setCalculating(false);
  };

  const shouldDisableLockMute = () => {
    if (new BigNumber(daoInfo.vekoi_underlying_bal).lte(0)) return true;

    return false;
  };

  const getLockMuteStatus = () => {
    if (new BigNumber(daoInfo.vekoi_underlying_bal).lte(0)) return "Insufficient amount";

    if (new BigNumber(daoInfo.shouldApprove).lt(koiAmount)) return "Approve";

    return "Redeem";
  };

  const setKAmount = (val) => {
    console.log(val);
    setKoiAmount(val);
  };


  const changeDelegate = async (_id, _del) => {
    setCalculating(true);
    await ChangeVEMuteDelegate(_id, _del);
    setCalculating(false);
    setModal(null);
  };

  const getDAOTab = () => {
    if (isDeposit == 0) {
      return (
        <>
          <ShadowWidget>
            <InputCoin
              value={new BigNumber(daoInfo.vekoi_underlying_bal).toNumber()}
              selectedAsset={getKoiToken()}
              disableEdit
              noBalance
              disableInput
              price={getKoiToken() ? getKoiToken().price : 0}
            />
          </ShadowWidget>
          <Spacer />
          <Spacer />
          <Spacer />
          <Spacer />
          <Spacer />
          <Spacer />

          <StatRow>
            <StatText bodyRegular color="gray400">
              veKOI NFTs
            </StatText>
            <StatTextHighlight bodyMedium>
              {daoInfo.vekoi_locks.length}
            </StatTextHighlight>
          </StatRow>

          <Spacer />
          <Spacer />
          <Spacer />

          <Button checkNetwork loading={calculating} onClick={() => lockMute()} disabled={shouldDisableLockMute()}>
            {getLockMuteStatus()}
          </Button>
        </>
      );
    } 
  };

  return (
    <Box noBreak>
      <CustomModal
        title={"Redeem veKOI"}
        isVisible={Modal.Lock == modal}
        onClose={() => {
          setModal(null);
          setTxId("");
        }}
      >
        <Content>
          <Icon icon="coinKoi" size="m" />
          <Text h4>{formatNumber(new BigNumber(daoInfo.vekoi_underlying_bal).toNumber(), 2)} KOI</Text>
          <Text bodyRegular>${new BigNumber(getKoiTokenPrice()).times(new BigNumber(daoInfo.vekoi_underlying_bal).toNumber()).toFixed(2)}</Text>
        </Content>

        <TxModal>
          {(w) => (
            <>
              <StatRow>
                <Text bodyRegular color="gray400">
                  Gas Cost Estimate
                </Text>
                <Text bodyRegular>{w("$" + gasRefund.actual)}</Text>
              </StatRow>
              <StatRow>
                <Text bodyRegular color="gray400">
                  Gas Refund Estimate
                </Text>
                <Text bodyRegular>{w("$" + gasRefund.refund)}</Text>
              </StatRow>
            </>
          )}
        </TxModal>
        {txId && (
          <StatRow>
            <Text bodyRegular color="gray400">
              Transaction ID
            </Text>
            <Text
              bodyRegular
              style={{cursor: "pointer"}}
              onClick={() => window.open("https://era.zksync.network/tx/" + txId, "_blank")}
            >
              {txId != "" ? reduceString(txId, 5, 3) : <Loading />}
            </Text>
          </StatRow>
        )}
      </CustomModal>

      <Layout>
        <Title style={{gridArea: "titlecontainer"}} breakpoint="860" title="Unlock veKOI" />
        <div style={{gridArea: "exchange", maxWidth: '550px'}}>
          <CardContent > 
            {getDAOTab()}
          </CardContent>
        </div>

      </Layout>
    </Box>
  );
};

const List = styled.ul`
  padding-right: 19px;
  min-height: 300px;
  max-height: 325px;
  max-width: 600px;
  overflow-y: auto;
`;

const Layout = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  grid-template-areas:
    "titlecontainer "
    "exchange ";

  row-gap: 12px;
  column-gap: 12px;

  & > *:first-child {
    margin-bottom: -12px;
  }

  @media (max-width: 860px) {
    max-width: 520px;
    margin: auto;
    grid-template-areas:
      "titlecontainer titlecontainer"
    "exchange exchange";
  }
`;

const Spacer = styled.div`
  width: 25px;
  height: 6px;
`;
const SpacerBorder = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px solid #2e3551;
`;
const TitleContainer = styled.div`
  positon: relative;
  grid-area: titlecontainer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AssetImage = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 5px;
`;

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`;

const StatText = styled(Text)`
  display: flex;
  align-items: center;
  text-align: right;
`;

const StatTextHighlight = styled(Text)`
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DataContainer = styled(CardContent)<{area?: string; extend?: boolean}>`
  grid-area: ${({area}) => area};

  ${(_) => _.extend && `flex: 1;`}
`;

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const ContainerText = styled(Text)`
  padding-bottom: 18px;
`;

const Separator = styled.div`
  height: 16px;
`;

const Content = styled(CardContent)`
  align-items: center;
  justify-content: space-between;
  height: 150px;
  margin-bottom: 50px;
`;

const CollectButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
`;

const RewardBody = styled(Text)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgb(var(--color-gray50));
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 6px 8px;
  width: fit-content;
  margin-right: 20px;
  margin-bottom: 10px;
`;

const Rewards = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  > * {
    &:last-child {
    }
  }

  @media (max-width: 500px) {
    justify-content: flex-start;

    > * {
      &:first-child {
      }
    }
  }
`;

const SDivider = styled.div`
  height: 12px;
  @media (max-width: 600px) {
    height: 12px;
  }
`;
