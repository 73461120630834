import React, {useState} from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import Slider from "rc-slider";

import "rc-slider/assets/index.css";

import {Grid} from "../../common/layout/Grid";

import {reduceString} from "../../utils/reduceString";
import {formatNumber} from "../../utils/formatNumber";
import unknownToken from "../../assets/images/icons/unknown_token.svg";
import {DaoBannerWidget} from "./DaoBannerWidget";
import {LoadingBox} from "../../common/loading/LoadingBox";
import {ShadowWidget} from "../../common/ShadowWidget";
import {VELockInfoKoi} from "./veLockInfoKoi";
import LaunchIcon from "@mui/icons-material/Launch";
import { Tooltip } from "../../ui/components";
import {calcTimeDelta} from "./Countdown";

import {
  Box,
  CardContent,
  Icon,
  InputCoin,
  Tabs,
  Button,
  LavaChip,
  Modal as CustomModal,
  Text,
  Empty,
  Title,
  Flex,
  Message,
  Loading,
} from "../../ui/components";

import {useWalletHook} from "../../web3/walletHook";
import {WalletState} from "../../state";
import {useWalletSelector} from "../../state/hooks";

import {useAccount} from "wagmi";
import {sendTransactionGaEvent} from "../../utils";
import {TxModal} from "../+common/TxModal";

interface ParamTypes {
  inputCurrency: string;
  outputCurrency: string;
}

enum Modal {
  Lock,
  ClaimRewards,
  Redeem
}

export const DaoScreen = () => {
  const [lockTime, setLockTime] = useState(7);
  const {isConnected} = useAccount();

  const [koiAmount, setKoiAmount] = useState("0");
  const [delegate, setDelegate] = useState("");
  const [txId, setTxId] = useState("");
  const [gasSaved, setGasSaved] = useState("");
  const [confirmTime, setConfirmTime] = useState("");

  const [isDeposit, setIsDeposit] = useState(1);
  const [calculating, setCalculating] = useState(false);

  const [modal, setModal] = useState<Modal | null>(null);

  const {getKoiToken, getKoiTokenPrice, RedeemVEKOI, LockKoi, ChangeVEMuteDelegate, collectVeRewards, getDAOInfo, unlockVEKOI} =
    useWalletHook<true>();
  const daoInfo = useWalletSelector((state: WalletState) => state.daoInfo as any);
  const gasRefund = useWalletSelector((state: WalletState) => state.gasEstimateRefund);

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const timeframe = 1719939600; // 12PM CT
  const rewardInterval = 60 * 60 * 24;

  React.useEffect(() => {
    const intervalFunc = () => {
      const currentTime = Date.now() / 1000;
      var nextTime = timeframe;
      while (currentTime > nextTime) {
        nextTime = nextTime + rewardInterval;
      }

      var res = calcTimeDelta(nextTime * 1000);

      //new epoch, reset and load new data
      if (hours == 0 && res.hours != 0 && daoInfo.veRewards) getDAOInfo();

      setDays(res.days);
      setHours(res.hours);
      setMinutes(res.minutes);
      setSeconds(res.seconds);
    };

    const interval = setInterval(() => {
      intervalFunc();
    }, 1000);

    intervalFunc();

    return () => clearInterval(interval);
  }, []);

  const getVeRewards = () => {
    if (daoInfo && daoInfo.veRewards) {
      return (
        <Flex column>
          <Rewards>
            {daoInfo.veRewards.map((item) => {
              if(new BigNumber(item.amount).gt(0))
              return (
                <RewardBody h6 color="black">
                  <Icon imageFallback={item.token ? item.token.logo : unknownToken} size={"s"} />
                  &nbsp;
                  {formatNumber(item.amount, 2)}
                  {" " + (item.token ? item.token.symbol : "")}
                </RewardBody>
              );
            })}
          </Rewards>
        </Flex>
      );
    }

    return <Rewards />;
  };

  const emissions = [
    //{token: "ZK", amount: new BigNumber(250000 / 2)},
    //{token: "WETH", amount: new BigNumber(2)},
    {token: "KOI", amount: new BigNumber(1200000)},
    //{token: "ZORRO", amount: new BigNumber(5000000)},
  ];


  const emissions_map = {
    //"ZK": new BigNumber(250000 / 2),
    //"WETH": new BigNumber(2),
    "KOI": new BigNumber(1200000),
    //"ZORRO": new BigNumber(5000000),
  }

  const getVeRewardsEmissions = () => {
    if (daoInfo && daoInfo.veRewards) {
      return (
        <Flex column>
          <Rewards>
            {daoInfo.veRewards.map((item) => {
              if(!emissions_map[item.token.symbol])
                return

              return (
                <RewardBody h7 color="black">
                  <Icon imageFallback={item.token ? item.token.logo : unknownToken} size={"xs"} />
                  &nbsp;
                  {formatNumber(emissions_map[item.token.symbol].div(30), 2)}
                  {""}
                </RewardBody>
              );
            })}
          </Rewards>
        </Flex>
      );
    }

    return <Rewards />;
  };

  const getUSDRewards = () => {
    if (daoInfo && daoInfo.veRewards) {
      var totRewards = new BigNumber(0);
      for (let i in daoInfo.veRewards) {
        totRewards = totRewards.plus(
          new BigNumber(daoInfo.veRewards[i].amount).times(daoInfo.veRewards[i].token.price),
        );
      }

      return "$" + formatNumber(totRewards.toFixed(), 2);
    }

    return "$0.00";
  };

  const collectRewards = async () => {
    setModal(Modal.ClaimRewards);
    setCalculating(true);
    try {
      const tx = await sendTransactionGaEvent("collect_ve_transaction", undefined, () => {
        return collectVeRewards();
      });

      if (tx.success == false) setModal(null);
      else {
        setTxId(tx.tx);
        setGasSaved(tx.gasSavedUSD!);
        setConfirmTime(tx.time);
      }
    } catch (e) {
      setModal(null);
    }
    setCalculating(false);
  };

  const redeemVeKoi = async (index) => {
    setCalculating(true);
    try {
      await sendTransactionGaEvent("unlock_ve_transaction", undefined, () => {
        return RedeemVEKOI(index);
      });
    } catch (e) {
      console.log(e);
    }
    setCalculating(false);
  };

  const lockMute = async () => {
    setModal(Modal.Lock);
    setCalculating(true);
    try {
      const tx = await sendTransactionGaEvent("lock_ve_transaction", undefined, () => {
        return LockKoi(koiAmount, lockTime, new BigNumber(daoInfo.shouldApprove).lt(koiAmount));
      });
      if (tx.success == false) setModal(null);
      else {
        setTxId(tx.tx);
        setGasSaved(tx.gasSavedUSD!);
        setConfirmTime(tx.time);
      }
    } catch (e) {
      setModal(null);
    }
    setCalculating(false);
  };

  const shouldDisableLockMute = () => {
    if (new BigNumber(koiAmount).lte(0) || new BigNumber(koiAmount).gt(getKoiToken().amount)) return true;

    return false;
  };

  const getLockMuteStatus = () => {
    if (new BigNumber(koiAmount).lte(0)) return "Insufficient amount";

    if (new BigNumber(koiAmount).gt(getKoiToken().amount)) return "Insufficient balance";

    if (new BigNumber(daoInfo.shouldApprove).lt(koiAmount)) return "Approve";

    return "Lock";
  };

  const setKAmount = (val) => {
    console.log(val);
    setKoiAmount(val);
  };

  const getRewardAPY = () => {
    var totUSD = new BigNumber(0);
    for (let i in daoInfo.veRewards) {
      for (let j in emissions) {
        if (daoInfo.veRewards[i].token.symbol == emissions[j].token) {
          totUSD = totUSD.plus(new BigNumber(daoInfo.veRewards[i].token.price).times(emissions[j].amount).times(12));
        }
      }
    }

    var totUSDKOI = new BigNumber(daoInfo.ve_supply_koi_solo).div(2).times(getKoiToken()?.price).toFixed(2);

    return totUSD.div(totUSDKOI).times(100).toFixed(2);
  };

    const redeemKoi = async () => {
      setModal(Modal.Redeem);
      setCalculating(true);
      try {
        const tx = await sendTransactionGaEvent("lock_ve_transaction", undefined, () => {
          return unlockVEKOI();
        });
        if (tx.success == false) setModal(null);
        else {
          setTxId(tx.tx);
          setGasSaved(tx.gasSavedUSD!);
          setConfirmTime(tx.time);
        }
      } catch (e) {
        setModal(null);
      }
      setCalculating(false);
    };
  
    const shouldDisableRedeemKoi = () => {
      if (new BigNumber(daoInfo.vekoi_underlying_bal).lte(0)) return true;
  
      return false;
    };
  
    const getRedeemKoiStatus = () => {
      if (new BigNumber(daoInfo.vekoi_underlying_bal).lte(0)) return "Insufficient amount";
  
      if (new BigNumber(daoInfo.shouldApprove).lt(koiAmount)) return "Approve";
  
      return "Redeem";
    };

  const changeDelegate = async (_id, _del) => {
    setCalculating(true);
    await ChangeVEMuteDelegate(_id, _del);
    setCalculating(false);
    setModal(null);
  };

  const getDAOTab = () => {
    if (isDeposit == 0) {
      return (
        <>
          <ShadowWidget>
            <InputCoin
              value={Number(koiAmount)}
              setValue={setKAmount}
              selectedAsset={getKoiToken()}
              disableEdit
              balance={getKoiToken() ? getKoiToken().balance : 0}
              price={getKoiToken() ? getKoiToken().price : 0}
            />
          </ShadowWidget>
          <Spacer />
          <Spacer />
          <Spacer />
          <Spacer />
          <Spacer />
          <Spacer />

          <StatRow>
            <StatText bodyRegular color="gray400">
              Vote Shares
            </StatText>
            <StatTextHighlight bodyMedium>
              {formatNumber(new BigNumber(koiAmount).times(lockTime).div(364).toFixed(), 2)} shares
            </StatTextHighlight>
          </StatRow>

          <StatRow>
            <StatText bodyRegular color="gray400">
              Lock time
            </StatText>
            <StatTextHighlight bodyMedium>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {new BigNumber(lockTime).toFixed(0)} days
              </div>
            </StatTextHighlight>
          </StatRow>

          <StatRow>
            <div
              style={{width: "100%", display: "flex", flexDirection: "column", marginLeft: "auto", marginRight: "auto"}}
            >
              <Slider
                onChange={(nextValues) => {
                  setLockTime(nextValues as number);
                }}
                railStyle={{backgroundColor: "rgb(var(--color-gray100))"}}
                trackStyle={{backgroundColor: "rgb(var(--color-overgrown))"}}
                handleStyle={{
                  width: "14px",
                  height: "14px",
                  borderRadius: "50px",
                  background: "rgb(var(--color-ultramoss))",
                  opacity: "1",
                  borderColor: "rgb(var(--color-overgrown))",
                }}
                min={7}
                max={364 * 2}
                defaultValue={7}
                step={7}
              />
            </div>
          </StatRow>

          <Spacer />
          <Spacer />
          <Spacer />

          <Button checkNetwork loading={calculating} onClick={() => lockMute()} disabled={shouldDisableLockMute()}>
            {getLockMuteStatus()}
          </Button>
        </>
      );
    } else if (isDeposit == 1) {
      return (
        <>
          <List>
            {!isConnected ? (
              <Empty
                title="Connect your wallet"
                subtitle="Connect your web3 wallet and check your active liquidity pool positions."
                showWallet
              />
            ) : daoInfo.user_locks && daoInfo.vekoi_locks.length > 0 ? (
              daoInfo.vekoi_locks.map((item, index) => (
                <VELockInfoKoi
                  calculating={calculating}
                  key={index}
                  info={item}
                  onRedeem={async () => {
                    await redeemVeKoi(item.index);
                  }}
                  onConvert={async () => {}}
                  price={getKoiTokenPrice()}
                  onChangeDelegate={async (res) => {
                    await changeDelegate(item.index, res);
                  }}
                />
              ))
            ) : (
              <Empty title="You own no veKOI" />
            )}
          </List>
        </>
      );
    } else if (isDeposit == 2){
            return (
              <>
                <ShadowWidget>
                  <InputCoin
                    value={new BigNumber(daoInfo.vekoi_underlying_bal).toNumber()}
                    selectedAsset={getKoiToken()}
                    disableEdit
                    noBalance
                    disableInput
                    price={getKoiToken() ? getKoiToken().price : 0}
                  />
                </ShadowWidget>
                <Spacer />
                <Spacer />
                <Spacer />
                <Spacer />
                <Spacer />
                <Spacer />
      
                <StatRow>
                  <StatText bodyRegular color="gray400">
                    veKOI NFTs
                  </StatText>
                  <StatTextHighlight bodyMedium>
                    {daoInfo.vekoi_locks.length}
                  </StatTextHighlight>
                </StatRow>
      
                <Spacer />
                <Spacer />
                <Spacer />
      
                <Button checkNetwork loading={calculating} onClick={() => redeemKoi()} disabled={shouldDisableRedeemKoi()}>
                  {getRedeemKoiStatus()}
                </Button>
              </>
          );
    }
  };

  return (
    <Box noBreak>
      <CustomModal
        title={"Lock"}
        isVisible={Modal.Lock == modal}
        onClose={() => {
          setModal(null);
          setTxId("");
        }}
      >
        <Content>
          <Icon icon="coinKoi" size="m" />
          <Text h4>{formatNumber(koiAmount, 2)} KOI</Text>
          <Text bodyRegular>${new BigNumber(getKoiTokenPrice()).times(koiAmount).toFixed(2)}</Text>
        </Content>
        <StatRow>
          <Text bodyRegular color="gray400">
            Lock Time
          </Text>
          <Text bodyRegular>{new BigNumber(lockTime).toFixed(0) + " days"}</Text>
        </StatRow>

        <TxModal>
          {(w) => (
            <>
              <StatRow>
                <Text bodyRegular color="gray400">
                  Gas Cost Estimate
                </Text>
                <Text bodyRegular>{w("$" + gasRefund.actual)}</Text>
              </StatRow>
              <StatRow>
                <Text bodyRegular color="gray400">
                  Gas Refund Estimate
                </Text>
                <Text bodyRegular>{w("$" + gasRefund.refund)}</Text>
              </StatRow>
            </>
          )}
        </TxModal>
        {txId && (
          <StatRow>
            <Text bodyRegular color="gray400">
              Transaction ID
            </Text>
            <Text
              bodyRegular
              style={{cursor: "pointer"}}
              onClick={() => window.open("https://era.zksync.network/tx/" + txId, "_blank")}
            >
              {txId != "" ? reduceString(txId, 5, 3) : <Loading />}
            </Text>
          </StatRow>
        )}
      </CustomModal>

      <CustomModal
        title={"Redeem veKOI"}
        isVisible={Modal.Redeem == modal}
        onClose={() => {
          setModal(null);
          setTxId("");
        }}
      >
        <Content>
          <Icon icon="coinKoi" size="m" />
          <Text h4>{formatNumber(new BigNumber(daoInfo.vekoi_underlying_bal).toNumber(), 2)} KOI</Text>
          <Text bodyRegular>${new BigNumber(getKoiTokenPrice()).times(new BigNumber(daoInfo.vekoi_underlying_bal).toNumber()).toFixed(2)}</Text>
        </Content>

        <TxModal>
          {(w) => (
            <>
              <StatRow>
                <Text bodyRegular color="gray400">
                  Gas Cost Estimate
                </Text>
                <Text bodyRegular>{w("$" + gasRefund.actual)}</Text>
              </StatRow>
              <StatRow>
                <Text bodyRegular color="gray400">
                  Gas Refund Estimate
                </Text>
                <Text bodyRegular>{w("$" + gasRefund.refund)}</Text>
              </StatRow>
            </>
          )}
        </TxModal>
        {txId && (
          <StatRow>
            <Text bodyRegular color="gray400">
              Transaction ID
            </Text>
            <Text
              bodyRegular
              style={{cursor: "pointer"}}
              onClick={() => window.open("https://era.zksync.network/tx/" + txId, "_blank")}
            >
              {txId != "" ? reduceString(txId, 5, 3) : <Loading />}
            </Text>
          </StatRow>
        )}
      </CustomModal>

      <CustomModal
        title={"Collect veRewards"}
        isVisible={Modal.ClaimRewards == modal}
        onClose={() => {
          setModal(null);
          setTxId("");
        }}
      >
        <Content>
          <Flex justify="center" grow align="center">
            {getVeRewards()}
          </Flex>
        </Content>
        <CustomModal.Rows>
          <TxModal>
            {(w) => (
              <>
                <StatRow>
                  <Text bodyRegular color="gray400">
                    Gas Cost Estimate
                  </Text>
                  <Text bodyRegular>{"$" + gasRefund.actual}</Text>
                </StatRow>
                <StatRow>
                  <Text bodyRegular color="gray400">
                    Gas Refund Estimate
                  </Text>
                  <Text bodyRegular>{"$" + gasRefund.refund}</Text>
                </StatRow>
              </>
            )}
          </TxModal>
          {txId && (
            <CustomModal.Row title="Transaction ID">
              <Text
                bodyRegular
                style={{cursor: txId ? "pointer" : undefined}}
                onClick={() => txId && window.open("https://era.zksync.network/tx/" + txId, "_blank")}
              >
                {txId != "" ? reduceString(txId, 5, 3) : <Loading />}
              </Text>
            </CustomModal.Row>
          )}
        </CustomModal.Rows>
      </CustomModal>

      <Layout>
        <Title style={{gridArea: "titlecontainer"}} breakpoint="860" title="Vote Escrow Koi" />

        <div style={{gridArea: "exchange"}}>
          <CardContent>
            <Box>
              <Tabs
                onSelect={(val) => setIsDeposit(val)}
                standalone
                full
                tabs={[
                  {value: 1, label: "Owned"},
                  {value: 2, label: "Unlock"},
                ]}
              />
            </Box>

            <Separator />

            {getDAOTab()}
          </CardContent>

          <SDivider />

          <CardContent>
            <CardContent.Title title="My Rewards" />
            <CardContent.Content>
          
              <StatRow>
                <Flex column grow padding="5">
                  <Text h3 align="center">
                    {getUSDRewards()}
                  </Text>
                </Flex>
              </StatRow>
              <StatRow>{getVeRewards()}</StatRow>
              <CollectButton
                checkNetwork
                loading={calculating}
                onClick={() => {
                  collectRewards();
                }}
                disabled={getUSDRewards() == "$0.00"}
              >
                Collect
              </CollectButton>
            </CardContent.Content>
          </CardContent>
        </div>

        <div style={{gridArea: "pool_stats"}}>
          <LavaChip
            heading="Total Value Locked"
            palette="green"
            value={daoInfo && daoInfo.success ? "$" + formatNumber(daoInfo.tvl, 2) : "$0.00"}
            loading={daoInfo.success != true}
          />
        </div>

        <div style={{gridArea: "reward_stats", display: 'none'}}>
          <LavaChip
            heading="New Rewards In"
            palette="purple"
            value={`${days}d:${hours}h:${minutes}m:${seconds}s`}
            loading={daoInfo.success != true}
          >
            {daoInfo.success == true &&
            <>
                        <Message>
                        {getRewardAPY()}% APY based on max lock
                      </Message>
                      </>
                      
            }


          </LavaChip>
        </div>

        <div style={{gridArea: "daily_rewards", display: "none"}}>
        <CardContent>
            <CardContent.Title title="Daily rewards" />
            <CardContent.Content>
            <StatRow>{getVeRewardsEmissions()}</StatRow>
              <Message error>
                Rewards are subject to change
              </Message>
            </CardContent.Content>
          </CardContent>
        </div>

        <DataContainer area="my_stats">
          <DataContainerColumn>
            <ContainerText h6>veKOI Stats</ContainerText>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Total Locked KOI
              </StatText>
              <StatTextHighlight bodyMedium>
                {daoInfo.success != true ? (
                  <LoadingBox />
                ) : (
                  formatNumber(new BigNumber(daoInfo.total_locked_koi), 2) + " KOI"
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Total veKOI Vote Shares
              </StatText>
              <StatTextHighlight bodyMedium>
                {daoInfo.success != true ? (
                  <LoadingBox />
                ) : (
                  formatNumber(new BigNumber(daoInfo.ve_supply_koi_solo), 2) + " shares"
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                User Cumalative Locked KOI
              </StatText>
              <StatTextHighlight bodyMedium>
                {daoInfo.success != true ? (
                  <LoadingBox />
                ) : (
                  formatNumber(new BigNumber(daoInfo.vekoi_underlying_bal), 2) + " KOI"
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                User veKOI Vote Shares
              </StatText>
              <StatTextHighlight bodyMedium>
                {daoInfo.success != true ? (
                  <LoadingBox />
                ) : (
                  formatNumber(new BigNumber(daoInfo.vekoi_balance_user).toPrecision(8), 2) + " shares"
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                User Vote Share Percentage
              </StatText>
              <StatTextHighlight bodyMedium>
                {daoInfo.success != true ? <LoadingBox /> : daoInfo.share + "%"}
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>

        <DataContainer area="pool_details">
          <DataContainerColumn>
            <ContainerText h6>Contract Details</ContainerText>
            <StatRow>
              <StatText bodyRegular color="gray400">
                KOI address
              </StatText>
              <StatTextHighlight bodyMedium>
                {daoInfo.success != true ? <LoadingBox /> : reduceString(daoInfo.koi_address, 7, 4)}
                <LaunchIcon
                  style={{width: "15px", marginLeft: "5px", color: "rgb(var(--color-overgrown))", cursor: "pointer"}}
                  onClick={() => {
                    window.open("https://era.zksync.network/address/" + daoInfo.koi_address, "_blank");
                  }}
                />
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                veKOI address
              </StatText>
              <StatTextHighlight bodyMedium>
                {daoInfo.success != true ? <LoadingBox /> : reduceString(daoInfo.vekoi_address, 7, 4)}
                <LaunchIcon
                  style={{width: "15px", marginLeft: "5px", color: "rgb(var(--color-overgrown))", cursor: "pointer"}}
                  onClick={() => {
                    window.open("https://era.zksync.network/address/" + daoInfo.vekoi_address, "_blank");
                  }}
                />
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>

        <DaoBannerWidget />
      </Layout>
    </Box>
  );
};

const List = styled.ul`
  padding-right: 19px;
  min-height: 300px;
  max-height: 325px;
  overflow-y: auto;
`;

const Layout = styled(Grid)`
  grid-template-columns: 3fr 4fr;
  grid-template-areas:
    "titlecontainer titlecontainer"
    "banner banner"
    "pool_stats exchange"
    "my_stats exchange"
    "pool_details exchange"
    "empty exchange";

  row-gap: 12px;
  column-gap: 12px;

  & > *:first-child {
    margin-bottom: -12px;
  }

  @media (max-width: 860px) {
    max-width: 520px;
    margin: auto;
    grid-template-areas:
      "titlecontainer titlecontainer"
      "pool_stats pool_stats"
      "exchange  exchange"
      "my_stats my_stats"
      "pool_details pool_details"
      "banner banner";
  }
`;

const Spacer = styled.div`
  width: 25px;
  height: 6px;
`;
const SpacerBorder = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px solid #2e3551;
`;
const TitleContainer = styled.div`
  positon: relative;
  grid-area: titlecontainer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AssetImage = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 5px;
`;

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`;

const StatText = styled(Text)`
  display: flex;
  align-items: center;
  text-align: right;
`;

const StatTextHighlight = styled(Text)`
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DataContainer = styled(CardContent)<{area?: string; extend?: boolean}>`
  grid-area: ${({area}) => area};

  ${(_) => _.extend && `flex: 1;`}
`;

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const ContainerText = styled(Text)`
  padding-bottom: 18px;
`;

const Separator = styled.div`
  height: 16px;
`;

const Content = styled(CardContent)`
  align-items: center;
  justify-content: space-between;
  height: 150px;
  margin-bottom: 50px;
`;

const CollectButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
`;

const RewardBody = styled(Text)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgb(var(--color-gray50));
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 6px 8px;
  width: fit-content;
  margin-right: 20px;
  margin-bottom: 10px;
`;

const Rewards = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  > * {
    &:last-child {
    }
  }

  @media (max-width: 500px) {
    justify-content: flex-start;

    > * {
      &:first-child {
      }
    }
  }
`;

const SDivider = styled.div`
  height: 12px;
  @media (max-width: 600px) {
    height: 12px;
  }
`;
